<template>
    <div class="mt-2">
        <div class="card card-body" >
            <div class="small text-muted fw-bold trigger-hover d-flex" v-if="!item.isNewRecord">
                {{item.CreatedBy}} - {{Utils.formatDate(item.Updated)}}
                <span class="display-onhover ms-2" v-if="!isEditCloseOut && !isEdit">
                    <button class="btn btn-link py-0 px-1" @click="reply(item)">Reply</button>
                    <button class="btn btn-link py-0 m-0 px-1" v-if="!item.hasReply && !item.Completed" @click="isEdit=true">Edit</button>
                    <OPersonLookup v-model="item.AssignedTo_ID" 
                        :bind="sel=>{item.AssignedTo_ID = sel.ID;item.AssignedTo = sel.Name;dataObject.save(item.index)}"
                        :viewName="personsViewName"
                        :whereClause="personsWhereClause">
                        <template #target="{target}">
                            <button class="btn btn-link py-0 m-0 px-1" :ref="target">{{$t('Assign')}}</button>
                        </template>
                           
                     
                    </OPersonLookup>    


                    <OActionDelete confirm v-if="!item.hasReply" class="btn btn-link py-0 text-danger px-1" :row = "item"/>
                </span>
            </div>
            <div v-if="!item.isNewRecord && !isEdit">
                {{item.Comment}}
               
            </div>
            
            
        
            <div class="small" v-if="item.isNewRecord || isEdit">
                <label  v-if="item.isNewRecord"  class="form-label">{{$t("New comment")}}</label>
                <OTextArea rows="2" class="form-control rounded-0" v-model="item.Comment" :placeholder="$t('Comment...')"></OTextArea>
                <OActionSave :row = "item" @click="isEdit=false;isEditCloseOut=false;"/>
    
                <button class="btn btn-sm btn-link py-0 m-0 px-1"  @click="()=>cancel(item)">{{$t("Cancel")}}</button>
                
            </div>
            <div v-if="item.AssignedTo_ID && !item.Completed && !item.isNewRecord">
                <i class="ms-1 text-info"> {{$t("Assigned to")}}: {{item.AssignedTo}}
                <ODatePicker v-model="item.DueDate" class="d-inline-flex">
                    
                    <button class="btn btn-sm btn-link py-0 m-0 px-1" v-if="!item.DueDate">{{$t('Set Due Date')}}</button>
                    <template v-if="item.DueDate">
                        {{$t('Due Date')}}:
                        <button class="btn btn-sm btn-link py-0 m-0 px-1">{{Utils.formatDate(item.DueDate,"Short Date")}}</button>
                    </template>
                   
                </ODatePicker>
                </i>
                <OTextArea rows="1" class="form-control rounded-0" v-model="item.CloseOutComment" :placeholder="$t('Type here to complete...')"></OTextArea>
                <OActionSave :row = "item" @onSave="(it)=>{if(it.CloseOutComment) it.Completed = new Date()}"/>
                <OActionCancel :row = "item"/>
    
           
            </div>

            <div class="ms-2" v-if="item.Completed && !item.isNewRecord">
                <div class="small text-muted fw-bold trigger-hover d-flex" v-if="!item.isNewRecord">
                    {{$t('Completed By')}}: {{item.CompletedBy}} - {{Utils.formatDate(item.Completed)}}
                    <span class="display-onhover ms-2" v-if="!isEditCloseOut && !isEdit">
                        <button class="btn btn-link py-0 m-0 px-1"  @click="isEditCloseOut=true">Edit</button>
                    </span>
                </div>
                <template v-if="!isEditCloseOut">
                    {{item.CloseOutComment}}
                </template>
                <template v-if="isEditCloseOut">
                    <OTextArea rows="2" class="form-control rounded-0" v-model="item.CloseOutComment" :placeholder="$t('Type here to complete...')" ></OTextArea>
                    <OActionSave :row = "item" @click="isEdit=false;isEditCloseOut=false;"/>
                    <button class="btn btn-sm btn-link py-0 m-0 px-1"  @click="()=>cancel(item)">{{$t("Cancel")}}</button>
                </template>
            </div>
         
        </div>

        <template v-if="!item.isNewRecord">
            <div class="ms-2" v-for="childItem in childItems">
                <Comment v-if="childItem" :item="childItem" :dataObject="dataObject" class="ms-2"></Comment>
            </div>
            
        </template>
      
            
        
    </div>

</template>

<script setup lang="ts">
    import {ref,computed,ComputedRef} from 'vue';
    import Utils from 'o365.modules.utils.js';
    import {type DataObject} from 'o365.modules.DataObject.ts';
    import OTextArea from 'o365.vue.components.TextArea.vue';
    import OActionSave from 'o365.vue.components.Action.Save.vue';
    import OActionCancel from 'o365.vue.components.Action.Cancel.vue';
    import OActionDelete from 'o365.vue.components.Action.Delete.vue';
    import OPersonLookup from 'o365.vue.components.PersonsLookup.vue';
    import ODatePicker from 'o365.vue.components.DatePicker.vue';
    
  

    interface IItem{
        Comment:string,
        CreatedBy:string,
        CloseOutComment:string,
        CompletedBy_ID:number,
        CompletedBy:string,
        Updated:string,
        DueDate:string,
        Completed:string,
        AssignedTo:string,
        AssignedTo_ID:number,
        ParentComment_ID:number,
        ID:number,
        index:number,
        isNewRecord:boolean,
        hasReply:ComputedRef<boolean>,
    }

    interface Props{
        item:IItem,
        dataObject:DataObject,
        personsViewName?: string,
        personsWhereClause?: string
    }
    const isEdit = ref(false);
    const isEditCloseOut = ref(false);

    const props = defineProps<Props>();
    const childItems = computed(()=> {return props.dataObject.data.filter((x:IItem)=>x.ParentComment_ID === props.item.ID)});

    const reply = (item)=>{
        props.dataObject.createNew({ParentComment_ID: item.ID});
    }

    props.item.hasReply = computed(()=> {return props.dataObject.data.find((x:IItem)=>x.ParentComment_ID === props.item.ID && !x.isNewRecord) !== undefined?true:false});

    const cancel = (item)=>{
        isEdit.value = false;
        isEditCloseOut.value = false;
        props.dataObject.cancelChanges(item.index);
    }
   
 

  

 

</script>
<style scoped>

    .trigger-hover .display-onhover{
        display: none;
    }
    .trigger-hover:hover .display-onhover{
        display: inline-block;
        line-height: 1.3;
    }

    .btn-link{
        text-decoration: none;
        line-height: 1rem;;
    }
    .btn-link:hover{
        text-decoration: underline;
    }
</style>